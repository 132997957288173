import { AppProps } from 'next/app'
import { supabase } from '../utils/initSupabase'
import { UserContextProvider } from '../lib/UserContext'
import { Toaster } from 'react-hot-toast'
import Head from 'next/head'

import '../assets/main.css';
import '../assets/chrome-bug.css';

function MyApp({ Component, pageProps }: AppProps) {
  return <UserContextProvider supabaseClient={supabase}>
    <Head>
      <link rel="icon" href="https://static.desica.uk/hotlink-ok/favicon.ico" />
    </Head>
    <Toaster position="top-right" />
    <Component {...pageProps} />
  </UserContextProvider>
}

export default MyApp
